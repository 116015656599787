import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/header/Header90Days";
import Footer from "../components/footer/Footer90Days";
import Loader from "../components/Loader";
import { useParams, useNavigate } from "react-router-dom";
import { url } from "../constants/url";

const Billing = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [downLoadLink, setDownLoadLink] = useState(null);

  let { uuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPdfLink = async () => {
      setLoading(true);
      setError(null);
      try {
        const requestBody = {
          unique_id: uuid
        };

        const response = await axios.post(
          url + "api/v1/selenium_automations/get_pdf_content/",
          requestBody,
          {
            responseType: "blob"
          }
        );

        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        setPdfUrl(pdfUrl);
        setDownLoadLink(pdfUrl);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          navigate("/404");
        } else {
          navigate("/404");
          setError("Oops! Unable to fetch the PDF.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPdfLink();
  }, [uuid, navigate]);

  const handleDownload = () => {
    if (!downLoadLink) return;

    const link = document.createElement("a");
    link.href = downLoadLink;
    link.setAttribute("download", "Bill.pdf"); // Customize the file name
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header onDownload={handleDownload} downLoadLink={downLoadLink} />
      <div className="flex-grow container max-w-5xl mx-auto flex flex-col items-center justify-start py-20 px-4">
        {error && <div className="text-red-600 text-center py-2">{error}</div>}
        {isLoading ? (
          <Loader loadingText={"Fetching your bill"} />
        ) : (
          <>
            {pdfUrl && (
              <div className="w-full flex justify-center">
                <iframe
                  src={`${pdfUrl}#toolbar=0&view=FitH&scrollbar=0&navpanes=0`}
                  title="Bill PDF"
                  allow="download"
                  className="w-full h-[520px] md:h-[970px] md:w-11/12 lg:w-9/12"
                  style={{
                    backgroundColor: "white",
                    border: "0",
                    boxShadow: "0 0 10px rgba(0,0,0,0.1)"
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Billing;
