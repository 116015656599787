import React from "react";
import LOGO from "../../LogoNew.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function Header({ onDownload, downLoadLink }) {
  return (
    <div className="relative">
      <div className="bg-white h-20 container mx-auto text-gray-800 px-4 sm:px-5 flex justify-between items-center">
        <img src={LOGO} alt="Logo" className="h-16 w-auto" />
        <div
          title="Appointment Type"
          className="hidden md:block text-xl sm:text-2xl md:text-4xl text-gray-800 font-bold truncate max-w-[40%]"
        >
          {"Your Medical Receipts"}
        </div>
        <button
          onClick={onDownload}
          className="w-32 h-10 bg-[#00c19c] rounded-lg text-white flex items-center justify-center shadow-lg transition-transform transform hover:scale-105"
          disabled={!downLoadLink} // Disable button if no download link
        >
          <FontAwesomeIcon icon={faDownload} className="mr-2" />
          Download
        </button>
      </div>
    </div>
  );
}

export default Header;
