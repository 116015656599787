import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./404";
import Billing from "./pages/billing";

function App() {
  return (
      <Router>
        <div className="flex flex-col min-h-screen">
          <Routes>
            <Route path="/billing/:uuid" element={<Billing />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;

